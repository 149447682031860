import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaCrown } from 'react-icons/fa';

const StudentRanking = () => {
  const [students, setStudents] = useState([]);
  const [sortedStudents, setSortedStudents] = useState([]);
  const [durationType, setDurationType] = useState('last3Months');
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    fetch('/students.json')
      .then(response => response.json())
      .then(data => {
        setStudents(data);
        sortStudentsByDuration(data, 'last3Months');
      })
      .catch(error => console.error('Error loading student data:', error));
  }, []);

  const calculateLast3MonthsDuration = (studyDuration) => {
    const months = Object.keys(studyDuration);
    const last3Months = months.slice(-3);
    return last3Months.reduce((total, month) => total + (studyDuration[month] || 0), 0);
  };

  const calculateTotalDuration = (studyDuration) => {
    return Object.values(studyDuration).reduce((total, monthDuration) => total + monthDuration, 0);
  };

  const sortStudentsByDuration = (data, type) => {
    const sorted = [...data].sort((a, b) => {
      const aDuration = type === 'last3Months'
        ? calculateLast3MonthsDuration(a.studyDuration)
        : calculateTotalDuration(a.studyDuration);
      const bDuration = type === 'last3Months'
        ? calculateLast3MonthsDuration(b.studyDuration)
        : calculateTotalDuration(b.studyDuration);
      return bDuration - aDuration;
    });
    setSortedStudents(sorted);
  };

  const handleDurationChange = (type) => {
    setDurationType(type);
    sortStudentsByDuration(students, type);
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  const getCrownColor = (index) => {
    if (index === 0) return 'gold';
    if (index === 1) return 'silver';
    if (index === 2) return '#cd7f32';
    return 'transparent';
  };

  return (
    <PageWrapper>
      <Header>
        <Title>IT Corner Learning Ranking</Title>
        <ButtonWrapper>
          <Button onClick={() => handleDurationChange('last3Months')}>3 か月</Button>
          <Button onClick={() => handleDurationChange('total')}>合計</Button>
        </ButtonWrapper>
      </Header>
      <RankingWrapper>
        {sortedStudents.map((student, index) => (
          <StudentCard key={student.id} onClick={() => handleStudentClick(student)}>
            {index < 3 && (
              <CrownIcon color={getCrownColor(index)}>
                <FaCrown />
              </CrownIcon>
            )}
            {student.avatar ? (
              <Avatar src={student.avatar} alt={student.name} hasAvatar />
            ) : (
              <NoAvatarPlaceholder>
                {student.name[0]}
              </NoAvatarPlaceholder>
            )}
            <StudentInfo>
              <StudentName>{student.name}</StudentName>
              <Details>学習量: {durationType === 'last3Months' ? calculateLast3MonthsDuration(student.studyDuration) : calculateTotalDuration(student.studyDuration)} 時間</Details>
            </StudentInfo>
          </StudentCard>
        ))}
      </RankingWrapper>

      {selectedStudent && (
        <Modal onClick={() => setSelectedStudent(null)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            {selectedStudent.avatar ? (
              <Avatar src={selectedStudent.avatar} alt={selectedStudent.name} />
            ) : (
              <NoAvatarPlaceholder>
                {selectedStudent.name[0]}
              </NoAvatarPlaceholder>
            )}
            <h4>{selectedStudent.name}が取得した認証</h4>
            <CertificateList>
              {selectedStudent.certificates.map((certificate, certIndex) => (
                <CertificateItem key={certIndex}>{certificate}</CertificateItem>
              ))}
            </CertificateList>
            <CloseButton onClick={() => setSelectedStudent(null)}>閉じる</CloseButton>
          </ModalContent>
        </Modal>
      )}
    </PageWrapper>
  );
};

// 样式组件

const PageWrapper = styled.div`
  background: radial-gradient(circle, rgba(18, 32, 47, 1) 0%, rgba(8, 12, 16, 1) 100%);
  min-height: 100vh;
  padding: 5px;
  color: #ffffff;
  font-family: 'Orbitron', sans-serif; /* 科技感字体 */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: clamp(1.5rem, 4vw, 3rem); /* 自适应字体大小 */
  color: #00ffff; /* 霓虹蓝色 */
  text-shadow: 0 0 10px #00ffff;
  white-space: nowrap; /* 防止文字换行 */
  overflow: hidden; /* 如果超出范围，隐藏多余文字 */
  text-overflow: ellipsis; /* 在文字超出容器时显示省略号 */
`;


const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background: linear-gradient(145deg, #1e90ff, #00ced1);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 255, 255, 0.4);

  &:hover {
    background: linear-gradient(145deg, #00ced1, #1e90ff);
    box-shadow: 0 6px 20px rgba(0, 255, 255, 0.6);
  }
`;

const StudentCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  width: 250px; /* 默认宽度 */
  box-shadow: 0 4px 15px rgba(0, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; /* 添加底部边距以分隔行 */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 255, 255, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%; /* 在小屏幕上宽度为100% */
    max-width: 400px; /* 限制最大宽度以保持美观 */
  }

  @media (max-width: 480px) {
    width: 100%;
    max-width: none; /* 去掉最大宽度限制 */
  }
`;

const RankingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 居中对齐 */
  gap: 20px;
  padding: 10px; /* 缩小边距 */
`;

// 如果需要，可以在 `StudentInfo` 中调整字体大小以适应小屏幕
const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%; /* 确保信息在小屏幕上撑满宽度 */
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #00ffff;
  margin-bottom: 10px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
`;

const NoAvatarPlaceholder = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(0, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 2rem; /* 增大字体大小 */
  color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
  text-align: center;
`;

const StudentName = styled.h3`
  margin: 0;
  font-size: 1.2em;
  color: #00ffff;

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const Details = styled.span`
  font-size: 0.9em;
  color: #00ffff;
  text-shadow: 0 0 5px #00ffff;

  @media (max-width: 480px) {
    font-size: 0.8em;
  }
`;

const CrownIcon = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  color: ${(props) => props.color};
  font-size: 24px;
  text-shadow: 0 0 10px ${(props) => props.color};
`;


const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #1e1e2f;
  padding: 15px;
  border-radius: 20px;
  max-width: 600px;
  width: 80%;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  color: #fff;
  position: relative;
  overflow: hidden;

  /* Neon glow effect */
  border: 2px solid #00f0ff;
  box-shadow: 0 0 15px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00f0ff, 0 0 40px #00f0ff;
`;

const CertificateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px auto; /* Centers the list horizontally */
  font-size: 1rem;
  color: #a9fef7;
  text-align: left;
  width: fit-content; /* Adjusts the list width to its content */
`;

const CertificateItem = styled.li`
  margin-bottom: 10px;
  padding-left: 30px; /* Increases padding for more space between the icon and text */
  position: relative;
  &:before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #00f0ff;
    font-size: 1.2rem;
  }
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 30px;
  background-color: transparent;
  color: #00f0ff;
  border: 2px solid #00f0ff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: #00f0ff;
    color: #1e1e2f;
  }
`;


export default StudentRanking;
